exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-events-strapi-event-slug-index-js": () => import("./../../../src/pages/events/{strapiEvent.slug}/index.js" /* webpackChunkName: "component---src-pages-events-strapi-event-slug-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signals-index-js": () => import("./../../../src/pages/signals/index.js" /* webpackChunkName: "component---src-pages-signals-index-js" */),
  "component---src-pages-signs-index-js": () => import("./../../../src/pages/signs/index.js" /* webpackChunkName: "component---src-pages-signs-index-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-sign-page-js": () => import("./../../../src/templates/sign-page.js" /* webpackChunkName: "component---src-templates-sign-page-js" */)
}

